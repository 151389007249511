<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <h1 class="display-1 font-weight-bold mb-3">問い合わせ 管理画面</h1>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="contacts"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.status`]="{ item }">
            <strong v-if="item.last_message.is_admin" class="success--text">返信済</strong>
            <strong v-else class="error--text">未返信</strong>
          </template>
          <template v-slot:[`item.last_message.post_datetime`]="{ item }">
            {{ item.last_message.post_datetime | moment }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              class="mr-2"
              @click="goChat(item)"
            >
              mdi-chat
            </v-icon>
            <v-icon
              class="mr-2"
              @click="deleteChat(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>no data.</template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">削除確認</span>
        </v-card-title>
        <v-card-text>
          このユーザからのお問い合わせを削除します。よろしいですか？
          <div class="grey lighten-3 my-3 pa-2 font-weight-black">
            {{ selectItem.questioner.username }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="deleteConfirm"
          >
            削除
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog=false"
          >
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    headers: [
      { text: 'ステータス', value: 'status'},
      { text: '質問者', value: 'questioner.username'},
      { text: '最終発言日', value: 'last_message.post_datetime'},
      { text: '操作', value: 'actions', sortable: false },
    ],
    contacts: [],
    loading: false,

    selectItem: {
      questioner: {}
    },
    deleteDialog: false,
  }),
  filters: {
    moment(value) {
      if (value) {
        return moment(value).format('YYYY/MM/DD HH:mm');
      } else {
        return '指定なし'
      }
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch () {
      this.$authAxios
        .get(`/api/contacts`)
        .then(response => {
          this.contacts = response.data
        })
    },
    goChat(item) {
      this.$router.push({name:'MessageAdmin', params: { id: item.id }, query: { q: item.questioner.username }})
    },
    deleteChat(item) {
      this.selectItem = Object.assign({}, item)
      this.deleteDialog = true
    },
    deleteConfirm() {
      this.$authAxios
        .delete(`/api/chat/${this.selectItem.id}`)
        .then(() => {
          this.fetch()
        })
        .finally(() => {
          this.deleteDialog = false
        })
    },
  }
};
</script>